import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Anomaly, AnomalyFields, AnomalyStage, AnomalyType, HistoryType, neededInfoCustomer, neededInfoValet } from '../models/anomaly';
import { BackendFilteringObject } from '@pop-valet/smart-table-backend-wrapper';
import { BackendPaginationResponse } from 'src/app/shared/models/backend-pagination-response';
import { ToastHandlerService } from 'src/app/shared/services/toast-handler.service';
import { Mission } from '../../common-mission/models/mission';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AnomalyService {
  constructor(private http: HttpClient,
              private toastService: ToastHandlerService,
              private translateService: TranslateService) {}

  public createAnomaly(anomaly: Anomaly, missionId: string): Promise<any> {

    anomaly = this.getMissingInfos(anomaly);

    const formData = new FormData();
    formData.append('missionId', missionId);
    formData.append('isRolling', anomaly.isRolling ? 'true' : 'false');

    for (const [key, value] of Object.entries(anomaly)) {
      if (value && typeof value !== 'boolean') {
        if (Array.isArray(value)) {
          for (const item of value) {
            formData.append(key, item);
          }
        } else {
          formData.append(key, value);
        }
      }
    }


    return this.http.post(`${environment.backendUrl}anomalies`, formData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.mission.service.new_anomaly_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.mission.service.new_anomaly_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public createNewHistory(anomalyId: string, description: string, type: HistoryType): Promise<any> {
    let params = new HttpParams();
    if (description && anomalyId && type) {
      params = params.append('anomalyId', anomalyId);
      params = params.append('description', description);
      params = params.append('type', type);
    }
  
    return this.http.post(`${environment.backendUrl}anomalies/new-history`, null, {
        params: params
      })
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.mission.service.new_history_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.mission.service.new_history_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }
  
  public getAnomalies(filteringObj: BackendFilteringObject, filter: any | null): Promise<BackendPaginationResponse<Anomaly>> {
    let query = new HttpParams();
    if(filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          query = query.append(key, filter[key]);
        }
      });
    }
    return this.http.get(`${environment.backendUrl}anomalies?paginate=true&${filteringObj.getBackendFiltering()}`,
      {
        params: query
      })
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getAnomalyById(id: string): Promise<Anomaly> {
    return this.http.get(`${environment.backendUrl}anomalies/${id}`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          return throwError(err);
        })
      ).toPromise();
  }

  public updateAnomaly(anomaly: Anomaly): Promise<Anomaly> {
    const formData = new FormData();
    formData.append('isRolling', anomaly.isRolling ? 'true' : 'false');

    for (const [key, value] of Object.entries(anomaly)) {
      if (value && typeof value !== 'boolean') {
        if (Array.isArray(value)) {
          for (const item of value) {
            formData.append(key, item);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    return this.http.put(`${environment.backendUrl}anomalies/${anomaly.id}`, formData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.anomalies.service.update_success')
          );
          return res.data;
        }),
        catchError(err => {
          return throwError(err);
        })
      ).toPromise();
  }

  public deleteAnomaly(id: string): Promise<any> {
    return this.http.delete(`${environment.backendUrl}anomalies/${id}`)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.anomalies.service.delete_success')
          );
          return res.data;
        }),
        catchError(err => {
          return throwError(err);
        })
      ).toPromise();
  }

  public getMissions(filter: string): Promise<Mission[]> {
    let query = new HttpParams();
    if(filter) {
      query = query.append('search', filter);
    }
    return this.http.get(`${environment.backendUrl}anomalies/missions`,
      {
        params: query
      })
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getAnomaliesExport(filter: any): Promise<any> {
    return this.http.post(`${environment.backendUrl}anomalies/start-export`, {
      stage: filter.stage,
      type: filter.type,
      customer: filter.customer
    })
      .pipe(
        catchError(err => {
          if (err.error.errorCode === 'export_already_in_progress') {
            this.toastService.showErrorToasts(
              this.translateService.instant('modules.dashboard.export.errors.export_already_in_progress')
            );
          } else {
            this.toastService.showErrorToastsResponse(err);
          }
          return throwError(err);
        })
      ).toPromise();
  }

  public treatInfractionAnomaly(anomalyId: string, denounce: boolean, email: string | null, files: File[] | null): Promise<any> {
    const formData = new FormData();
    formData.append('denounce', denounce ? 'true' : 'false');
    formData.append('anomalyId', anomalyId);
    if (email) {
      formData.append('email', email);
    }
    if (files) {
      for (let file of files) {
        formData.append('files', file);
      }
    }

    return this.http.post(`${environment.backendUrl}anomalies/treat-infraction`, formData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.anomaly.treat_dialog_infraction.success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public treatAccidentAnomaly(treatData: any): Promise<any> {
    const formData = new FormData();
    formData.append('anomalyId', treatData.anomalyId);
    formData.append('quotation', treatData.quotation);
    formData.append('insurance', treatData.insurance ? 'true' : 'false');

    if (treatData.insurance) {
      formData.append('message', treatData.message);
      for (let email of treatData.emails) {
        formData.append('emails', email);
      }
      if (treatData.files) {
        for (let file of treatData.files) {
          formData.append('files', file);
        }
      }
    } else {
      formData.append('valetResponsible', treatData.valetResponsible ? 'true' : 'false');
      formData.append('quotationValid', treatData.quotationValid ? 'true' : 'false');
      if (!treatData.quotationValid) {
        formData.append('weProovLink', treatData.weProovLink);
      }
    }

    return this.http.post(`${environment.backendUrl}anomalies/treat-accident`, formData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('modules.anomaly.treat_dialog_accident.success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  private getMissingInfos(anomaly: Anomaly): Anomaly {
    const missingInfoValetConditions: Record<AnomalyType, AnomalyFields[]> = neededInfoValet;
    const missingInfoValet = missingInfoValetConditions[anomaly.type].filter(key => !anomaly[key]);
    anomaly.missingInfoValet = missingInfoValet;

    const missingInfoCustomerConditions: Record<AnomalyType, AnomalyFields[]> = neededInfoCustomer;
    const missingInfoCustomer = missingInfoCustomerConditions[anomaly.type].filter(key => !anomaly[key]);
    anomaly.missingInfoCustomer = missingInfoCustomer;

    return anomaly;
  }
}