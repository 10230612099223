import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastHandlerService } from '../../shared/services/toast-handler.service';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ValetDocument, ValetDocuments } from '../../common-modules/common-valet/models/valet-document';

@Injectable({
  providedIn: 'root'
})
export class ValetProfileService {

  constructor(private http: HttpClient,
              private toastService: ToastHandlerService) {
  }

  public getValetDocuments(): Promise<ValetDocuments> {
    return this.http.get(`${environment.backendUrl}valets/document`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateValetDocument(document: ValetDocument, valetId?: string): Promise<ValetDocuments> {
    const body = new FormData();
    body.append('valetDocument', document.url);
    if (document.expiryDate) {
      body.append('expiryDate', document.expiryDate);
    }
    if (valetId) {
      body.append('valetId', valetId);
    }
    return this.http.post(`${environment.backendUrl}valets/document/${document.documentType}`,
      body)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }
}
