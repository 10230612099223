import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken, Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export interface GenericValidationActionModal {
  canceled?: boolean,
  confirmed?: boolean,
}
@Component({
  selector: 'pv-generic-validation-modal',
  templateUrl: './generic-validation-modal.component.html',
  styleUrls: ['./generic-validation-modal.component.scss']
})
export class GenericValidationModalComponent implements OnInit {
  @ViewChild('templateContainer', { static: true }) public templateRef: TemplateRef<any>;

  @Input() title: string;
  @Input() message: string;
  @Input() confirm: string;
  @Input() cancel: string;

  @Output() actionClicked: EventEmitter<GenericValidationActionModal> = new EventEmitter();

  constructor(
    public translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.title = this.title ?? this.translateService.instant('shared.generic_modal.title');
    this.message = this.message ?? this.translateService.instant('shared.generic_modal.message');
    this.confirm = this.confirm ?? this.translateService.instant('shared.generic_modal.confirm');
    this.cancel = this.cancel ?? this.translateService.instant('shared.generic_modal.cancel');
  }

  public cancelAction() {
    this.actionClicked.emit({ canceled: true });
  }

  public confirmAction() {
    this.actionClicked.emit({ confirmed: true });
  }
}
