import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'slnk-shared-input-validator-item',
  templateUrl: './shared-input-validator-item.component.html',
  styleUrls: ['./shared-input-validator-item.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          opacity: 0,
          // 'max-height': 0,
          overflow: 'hidden'
        }),
        animate('200ms ease-in', style({
          opacity: 1,
          // 'max-height': '200px',
        }))
      ]),
      transition(':leave', [
        style({
          position: 'absolute',
          width: '100%',
          top: 0
        }),
        animate('200ms ease-in', style({
          opacity: 0,
        }))
      ])
    ])
  ]
})
export class SharedInputValidatorItemComponent implements OnInit {
  @Input() validationFormControl: AbstractControl;
  @Input() validation: string;
  @Input() textAlign: string;

  constructor() {
  }

  ngOnInit() {
  }

}
