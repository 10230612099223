import { Injectable } from '@angular/core';
import {USER_ROLE} from '../models/user';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FrontChatService {

  private frontJsLoaded = false;
  private currentChatId = null;
  private currentUserHash = null;

  constructor(private authService: AuthService) { }

  private loadFrontJs() {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        resolve();
      });
    });
  }

  private getChatConfig(user: any) {
    let chatId;
    if (user?.role === USER_ROLE.ADMIN) {
      return null;
    } else if (user?.role === USER_ROLE.VALET || window.location.hostname.startsWith('recrutement')) {
      chatId = 'cafaf79a760b7804b4b67d76bbb26f23';
    } else {
      chatId = '68a31bb21602412f2389eacfee234da1';
    }

    const chatConfig: any = {
      useDefaultLauncher: true,
      chatId
    };

    if (user?.frontUserHash) {
      chatConfig.email = user.email;
      chatConfig.name = user.firstName + ' ' + user.lastName;
      chatConfig.userHash = user.frontUserHash;
    }

    return chatConfig;
  }

  async loadChat() {
    const user = await this.authService.getUser().toPromise();

    if (!this.frontJsLoaded) {
      await this.loadFrontJs();
      this.frontJsLoaded = true;
    }

    const chatConfig = this.getChatConfig(user);
    if (!chatConfig) {
      return;
    }

    const reloadChat = chatConfig.chatId !== this.currentChatId ||
      (chatConfig.userHash && chatConfig.userHash !== this.currentUserHash);

    if (reloadChat) {
      const w = window as any;
      w.FrontChat('shutdown');
      w.FrontChat('init', chatConfig);

      this.currentChatId = chatConfig.chatId;
      if (chatConfig.userHash) {
        this.currentUserHash = chatConfig.userHash;
      }
    }
  }
}
