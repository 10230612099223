import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {PvRecruitmentDialogComponent} from './pv-recruitment-dialog/pv-recruitment-dialog.component';
import {PvRecruitmentDialogReactivateAccountComponent} from './pv-recruitment-dialog-reactivate-account/pv-recruitment-dialog-reactivate-account.component';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PvRecruitmentService} from './pv-recruitment.service';
import {LogInWithToken} from '../../shared/store/user/auth.actions';
import {Store} from '@ngrx/store';
import {UserState} from '../../shared/store/user/auth.state';

@Component({
  selector: 'pv-pv-recruitment',
  templateUrl: './pv-recruitment.component.html',
  styleUrls: ['./pv-recruitment.component.scss']
})
export class PvRecruitmentComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];

  constructor(private dialogService: NbDialogService,
              private activeRoute: ActivatedRoute,
              private recruitementService: PvRecruitmentService,
              private store: Store<UserState>) { }

  ngOnInit(): void {
    this.subs.push(this.activeRoute.queryParams.subscribe(queryParams => {
      if (queryParams.page === 'login') {
        setTimeout(() => {
          this.showConnexionDialog();
        }, 0);
      } else if (queryParams.page === 'signup') {
        setTimeout(() => {
          this.showRecruitmentDialog();
        }, 0);
      } else if (queryParams.token) {
        const payload = {
          redirectUrl: '/recruitment-process',
          token: queryParams.token
        };
        this.store.dispatch(
          new LogInWithToken(payload)
        );
      }
    }));
    this.recruitementService.fbEvent('PageView').catch(error => {
      console.error(error);
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  public showRecruitmentDialog() {
    this.dialogService.open(PvRecruitmentDialogComponent, {
      context: {
        dialogStep: 0,
        retry: false
      }
    });
    this.recruitementService.gaPageEvent('recruitement-dialog');
    this.recruitementService.fbEvent('ViewContent').catch(error => {
      console.error(error);
    });
  }

  public showConnexionDialog() {
    this.dialogService.open(PvRecruitmentDialogReactivateAccountComponent, {});
    this.recruitementService.gaPageEvent('login-dialog');
  }

}
