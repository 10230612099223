import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {UtilsHelper} from '../helpers/utils.helper';


export class CustomValidators {
  public static requiredIfXNotEmpty(x: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      const xHasValue = !UtilsHelper.IsNilOrEmpty(parent?.get(x).value);
      const hasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (parent && xHasValue && !hasValue) {
        return { required: parent.get(x).value }
      } else {
        return null
      }
    }
  }

  public static requiredIfXTrue(xControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const xControl = parent.get(xControlName);
      if (!xControl) {
        return null;
      }

      const xHasValue = xControl.value;
      const controlValue = control.value;
      if (xHasValue === true && !controlValue) {
        return { required: true };
      }

      return null;
    };
  }
}
