import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {PvRecruitmentService} from '../pv-recruitment.service';
import {selectAuthState, UserState} from '../../../shared/store/user/auth.state';
import {GetUser, LogIn} from '../../../shared/store/user/auth.actions';
import {Store} from '@ngrx/store';
import {ToastHandlerService} from '../../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {GLOBAL_SETTINGS} from '../../../shared/services/global-config.service';
import {Router} from '@angular/router';
import {Candidate, DrivingLicenceType, DrivingLicenseAge} from '../models/Candidate';
import {PvRecruitmentDialogReactivateAccountComponent} from '../pv-recruitment-dialog-reactivate-account/pv-recruitment-dialog-reactivate-account.component';
import {ACCEPTED_VALET_FILE_TYPES} from "../../../shared/constants/constants";
import {
  VALET_DOCUMENT_TYPE,
  ValetDocument
} from "../../../common-modules/common-valet/models/valet-document";
import {ValetProfileService} from "../../../valet/services/valet-profile.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {CustomValidators} from "../../../shared/validators/CustomValidators";

declare const dataLayer: any[];

export interface LicensesTypesGroup {
  name: string,
  types: {
    name: string,
    value: string
  }[]
}

@Component({
  selector: 'pv-pv-recruitment-dialog',
  templateUrl: './pv-recruitment-dialog.component.html',
  styleUrls: ['./pv-recruitment-dialog.component.scss']
})
export class PvRecruitmentDialogComponent implements OnInit {
  public formFirstStep: FormGroup;
  public formSecondStep: FormGroup;
  public loginForm: FormGroup;
  public candidateUser: any;
  public candidate: Candidate;
  public eligibility: string;
  public blockedValet = false;
  public isSubmitting = false;
  public useMyData = false;
  public showConnectButton = false;
  public licensesTypesGroups: LicensesTypesGroup[];

  public fileName: string | File = '';
  public file: any;
  public acceptedValetFileTypes = ACCEPTED_VALET_FILE_TYPES;

  private destroy: Subject<void> = new Subject();

  @Input() retry: boolean;
  @Input() dialogStep: number;
  @Input() availableMetropolis: any[];

  constructor(protected dialogRef: NbDialogRef<PvRecruitmentDialogComponent>,
              private toastService: ToastHandlerService,
              private translateService: TranslateService,
              private store: Store<UserState>,
              private fb: FormBuilder,
              private recruitmentService: PvRecruitmentService,
              public router: Router,
              private dialogService: NbDialogService,
              private valetDocumentService: ValetProfileService) { }

  ngOnInit(): void {
    this.store.select(selectAuthState).subscribe(({user}) => {
      this.candidateUser = user;
    });
    this.store.dispatch(new GetUser());

    this.licensesTypesGroups = this.getLicensesTypesGroups();

    this.formFirstStep = this.fb.group({
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(GLOBAL_SETTINGS.patterns.email)]],
      phone: ['', [Validators.required]],
      password: ['', [Validators.required, this.passwordStrengthValidator()]],
      samePassword: ['', [Validators.required]],
      referralCode: [''],
    });

    if (this.retry === false) {
      this.formSecondStep = this.fb.group({
        metropolis: ['', [Validators.required]],
        hasAutoentrepreneurStatus: [null, [Validators.required]],
        hasInsurer: [null, [Validators.required]],
        noTVA: [null],
        siret: [''],
        drivingLicensePoints: ['', [Validators.required]],
        drivingLicenseAge: ['', [Validators.required]],
        drivingLicenseType: ['', [Validators.required]],
        licensesTypes: [[]],
        sourcingType: ['', [Validators.required]],
        sourcingNote: [''],
        nationality: ['', [Validators.required]],
        availableOnSaturday: [null, [Validators.required]],
        hasWGarage: [null, [Validators.required]],
        wGarage: ['', [CustomValidators.requiredIfXTrue('hasWGarage')]]
      });

      this.formSecondStep.get('hasWGarage').valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => {
        this.updateWGarageValidators(value);
      });
    } else if (this.retry === true) {
      this.formSecondStep = this.fb.group({
        hasAutoentrepreneurStatus: [null, [Validators.required]],
        hasInsurer: [null, [Validators.required]],
        noTVA: [null],
        siret: [''],
        drivingLicensePoints: ['', [Validators.required]],
        drivingLicenseAge: ['', [Validators.required]],
        drivingLicenseType: ['', [Validators.required]],
        availableOnSaturday: [null, [Validators.required]]
      });
    }

    this.formSecondStep.get('hasAutoentrepreneurStatus').valueChanges.subscribe(value => {
      const siretControl = this.formSecondStep.get('siret');
      const noTVAControl = this.formSecondStep.get('noTVA');
      if (value) {
        siretControl.setValidators([
          Validators.required,
          Validators.pattern('[0-9]{14}')
        ]);
        noTVAControl.setValidators([
          Validators.required
        ]);
      } else {
        siretControl.clearValidators();
        noTVAControl.clearValidators();`
        `
        siretControl.setValue('');
        noTVAControl.setValue(null);
      }
      siretControl.updateValueAndValidity();
      noTVAControl.updateValueAndValidity();
    });

    this.recruitmentService.getAllMetropolis().then(metropolises => {
      this.availableMetropolis = metropolises.map(metropolis => {
        return {
          value: metropolis.id,
          name: metropolis.name
        };
      });
    });
  }

  public getLicensesTypesGroups(): LicensesTypesGroup[] {
    return [{
      name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_a.title'),
      types: [{
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_a.license_a'),
        value: 'a'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_a.license_a1'),
        value: 'a1'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_a.license_a2'),
        value: 'a2',
      }]
    }, {
      name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_c_and_d.title'),
      types: [{
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_c_and_d.license_c'),
        value: 'c'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_c_and_d.license_c1'),
        value: 'c1'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_c_and_d.license_c1e'),
        value: 'c1e'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_c_and_d.license_d'),
        value: 'd'
      }]
    }, {
      name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_e.title'),
      types: [{
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_e.license_be'),
        value: 'be'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_e.license_ce'),
        value: 'ce'
      }, {
        name: this.translateService.instant('recruitment.popup.licenses_types_groups.license_category_e.license_de'),
        value: 'de'
      }]
    }];
  }

  private initLoginForm() {
    this.loginForm = this.fb.group({
      email: [this.formFirstStep.get('email').value, [Validators.required]],
      password: [this.formFirstStep.get('password').value, Validators.required],
      rememberMe: [true],
    });
  }

  private passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const password: string = control.value;

      const lengthError = password.length < 8;
      const uppercaseError = (password.match(/[A-Z]/g) || []).length < 1;
      const lowercaseError = (password.match(/[a-z]/g) || []).length < 1;
      const digitError = (password.match(/[0-9]/g) || []).length < 1;
      const specialCharError = (password.match(/[-!$%^&*@()_+|~=`{}\[\]:";'<>?,.\/]/g) || []).length < 1;

      return lengthError || uppercaseError || lowercaseError || digitError || specialCharError ? {
        lengthError: lengthError ? {value: password} : null,
        uppercaseError: uppercaseError ? {value: password} : null,
        lowercaseError: lowercaseError ? {value: password} : null,
        digitError: digitError ? {value: password} : null,
        specialCharError: specialCharError ? {value: password} : null,
      } : null;
    };
  }

  updateWGarageValidators(hasWGarage: boolean) {
    const wGarageControl = this.formSecondStep.get('wGarage');
    if (hasWGarage === false) {
      wGarageControl.setValue('');
    }
    wGarageControl.updateValueAndValidity();
  }

  previousStep() {
    this.dialogStep = this.dialogStep - 1;
  }

  nextStep() {
    if (this.formFirstStep.invalid) {
      this.formFirstStep.markAllAsTouched();
    } else if (
      this.formFirstStep.valid &&
      this.useMyData === true &&
      this.formFirstStep.get('password').value === this.formFirstStep.get('samePassword').value
    ) {
      this.recruitmentService.isBlocked(
        this.formFirstStep.get('firstName').value,
        this.formFirstStep.get('lastName').value
      ).then(data => {
        if (data.isBlocked) {
          this.blockedValet = true;
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.popup.forms.blocked_valet'),
            null,
            0
          );
        } else {
          this.dialogStep++;
        }
      });
    }

    if (!(this.formFirstStep.get('title') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_title')
      );
    }

    if (!(this.formFirstStep.get('firstName') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_first_name')
      );
    }

    if (!(this.formFirstStep.get('lastName') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_last_name')
      );
    }

    if (!(this.formFirstStep.get('phone') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_phone')
      );
    }

    if (!(this.formFirstStep.get('email') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_email')
      );
    }

    if (!(this.formFirstStep.get('password') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_password')
      );
    }

    if (!(this.formFirstStep.get('samePassword') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_samePassword')
      );
    }

    if (this.formFirstStep.get('password').value !== this.formFirstStep.get('samePassword').value) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_not_same_password')
      );
    }

    if (this.useMyData === false) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_checkbox_data')
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public showConnexionDialog() {
    this.closeDialog();
    this.dialogService.open(PvRecruitmentDialogReactivateAccountComponent, {});
    this.recruitmentService.gaPageEvent('login-dialog');
  }

  createAccountOrRetry() {
    if (this.formSecondStep.invalid) {
      this.formSecondStep.markAllAsTouched();
    } else if (this.formSecondStep.get('drivingLicensePoints').value > 12) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_license_points_max')
      );
    } else if (this.formSecondStep.valid && this.retry === false) {
      if (this.formSecondStep.get('hasInsurer').value && this.file === undefined) {
        this.toastService.showErrorToasts(
          this.translateService.instant('recruitment.popup.forms.error_no_document')
        );
      } else {
        this.isSubmitting = true;
        const candidate = this.generateCandidateObject();
        this.recruitmentService.createCandidate(candidate)
          .then(dataCandidate => {
            this.isSubmitting = false;
            this.candidate = dataCandidate;
            this.eligibility = dataCandidate.eligibilityStatus;
            setTimeout(() => {
              this.dialogStep = this.dialogStep + 1;
              this.sendEligibleEvent();
            });
          })
          .catch(error => {
            if (error === 'existing_user') {
              this.showConnectButton = true;
            } else {
              console.error('Erreur lors de la création du candidat', error);
            }
            this.isSubmitting = false;
          });
      }
    } else if (this.formSecondStep.valid && this.retry === true) {
      const candidate = this.generateCandidateObject()
      this.recruitmentService.updateCandidateEligibility(candidate).then(dataCandidate => {
        this.candidate = dataCandidate;
        this.eligibility = dataCandidate.eligibilityStatus;
        setTimeout(() => {
          this.dialogStep = this.dialogStep + 1;
          this.sendEligibleEvent();
        });
      })
    }

    if (!(this.formSecondStep.get('hasAutoentrepreneurStatus') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_auto_entrepreneur')
      );
    }

    if (!(this.formSecondStep.get('hasInsurer') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_has_insurer')
      );
    }

    if (!(this.formSecondStep.get('drivingLicensePoints') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_license_points')
      );
    }

    if (!(this.formSecondStep.get('drivingLicenseAge') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_license_age')
      );
    }

    if (!(this.formSecondStep.get('drivingLicenseType') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_license_type')
      );
    }

    if (!(this.formSecondStep.get('availableOnSaturday') as FormControl).valid) {
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.popup.forms.error_available_on_saturday')
      );
    }

    if (this.retry === false) {
      if (!(this.formSecondStep.get('metropolis') as FormControl).valid) {
        this.toastService.showErrorToasts(
          this.translateService.instant('recruitment.popup.forms.error_metropolis')
        );
      }

      if (!(this.formSecondStep.get('nationality') as FormControl).valid) {
        this.toastService.showErrorToasts(
          this.translateService.instant('recruitment.popup.forms.error_nationality')
        );
      }

      if (!(this.formSecondStep.get('hasWGarage') as FormControl).valid) {
        this.toastService.showErrorToasts(
          this.translateService.instant('recruitment.popup.forms.error_has_w_garage')
        );
      }

      if (this.formSecondStep.get('hasWGarage').value) {
        if (!(this.formSecondStep.get('wGarage') as FormControl).valid) {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.popup.forms.error_w_garage')
          );
        }
      }
    }
  }

  showAutoentrepreneurCreationMessage () {
    return !this.candidate.hasAutoentrepreneurStatus &&
      this.candidate.drivingLicenseAge === DrivingLicenseAge.moreThanOrEqual3 &&
      this.candidate.drivingLicensePoints >= 8 &&
      this.candidate.drivingLicenseType === DrivingLicenceType.european &&
      this.candidate.noTVA;
  }

  showEligibilityInvalidMessage () {
    return !this.candidate.hasAutoentrepreneurStatus &&
      (this.candidate.drivingLicenseAge === DrivingLicenseAge.lessThan5 ||
      this.candidate.drivingLicensePoints < 8 ||
      this.candidate.drivingLicenseType === DrivingLicenceType.foreign ||
      !this.candidate.noTVA);
  }

  startRecruitmentProcess() {
    this.initLoginForm();

    this.isSubmitting = true;
    const payload = {
      redirectUrl: '/recruitment-process',
      ...this.loginForm.getRawValue()
    };
    this.store.dispatch(
      new LogIn(payload)
    );

    // To improve
    this.store.select((a) => {
      return a;
    }).subscribe(val => {
      this.isSubmitting = false;
      if ((val as any)?.user?.error === false) {
        this.closeDialog();
      }
    });

    if (this.formSecondStep.get('hasInsurer').value) {
      setTimeout(() => {
        this.uploadFile()
      }, 1000);
    }
  }

  restartRecruitmentProcess() {
    window.location.reload()
  }

  checkLicencePoints() {
    if(this.formSecondStep.get('drivingLicensePoints').value > 12) {
      this.formSecondStep.get('drivingLicensePoints').setValue('12');
    }
  }

  private generateCandidateObject() {
    if (this.retry === false) {
      const candidateFirstStepFormValues = this.formFirstStep.getRawValue();
      const candidateSecondStepFormValues = this.formSecondStep.getRawValue();

      const candidate: any = {
        user: {
          title: candidateFirstStepFormValues.title,
          firstName: candidateFirstStepFormValues.firstName,
          lastName: candidateFirstStepFormValues.lastName,
          email: candidateFirstStepFormValues.email,
          phone: candidateFirstStepFormValues.phone,
          password: candidateFirstStepFormValues.password,
          metropolis: candidateSecondStepFormValues.metropolis,
          hasWGarage: candidateSecondStepFormValues.hasWGarage,
          wGarage: candidateSecondStepFormValues.wGarage
        },
        referralCode: candidateFirstStepFormValues.referralCode,
        hasAutoentrepreneurStatus: candidateSecondStepFormValues.hasAutoentrepreneurStatus,
        hasInsurer: candidateSecondStepFormValues.hasInsurer,
        noTVA: candidateSecondStepFormValues.noTVA,
        siret: candidateSecondStepFormValues.siret,
        drivingLicensePoints: candidateSecondStepFormValues.drivingLicensePoints,
        drivingLicenseAge: candidateSecondStepFormValues.drivingLicenseAge,
        drivingLicenseType: candidateSecondStepFormValues.drivingLicenseType,
        licensesTypes: candidateSecondStepFormValues.licensesTypes,
        sourcingType: candidateSecondStepFormValues.sourcingType,
        sourcingNote: candidateSecondStepFormValues.sourcingNote,
        nationality: candidateSecondStepFormValues.nationality,
        availableOnSaturday: candidateSecondStepFormValues.availableOnSaturday
      };

      return candidate;
    } else if (this.retry === true) {
      const candidateSecondStepFormValues = this.formSecondStep.getRawValue();

      const candidate: any = {
        hasAutoentrepreneurStatus: candidateSecondStepFormValues.hasAutoentrepreneurStatus,
        hasInsurer: candidateSecondStepFormValues.hasInsurer,
        noTVA: candidateSecondStepFormValues.noTVA,
        siret: candidateSecondStepFormValues.siret,
        drivingLicensePoints: candidateSecondStepFormValues.drivingLicensePoints,
        drivingLicenseAge: candidateSecondStepFormValues.drivingLicenseAge,
        drivingLicenseType: candidateSecondStepFormValues.drivingLicenseType
      };

      return candidate;
    }
  }

  private sendEligibleEvent() {
    dataLayer.push({
      event: 'eligibility'
    });
  }

  getFormControlStatus(formControl: AbstractControl) {
    return !formControl.valid && !formControl.untouched ? 'danger' : '';
  }

  resetFile() {
    if (this.file) {
      this.file = null;
      this.fileName = '';
    }
  }

  fileInputChanged(file: any) {
    if (file) {
      this.file = file;
      this.fileName = file.name;
    }
  }

  public uploadFile() {
    const document: ValetDocument = {
      url: this.file,
      documentType: VALET_DOCUMENT_TYPE.insurance
    };

    this.isSubmitting = true;
    this.valetDocumentService.updateValetDocument(document).then(res => {
      this.isSubmitting = false;
    }).catch(err => {
      console.log('error update document')
    });
  }
}
