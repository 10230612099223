import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { CdkPortal, TemplatePortalDirective } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'pv-common-autocomplete-option-dropdown',
  templateUrl: './common-autocomplete-option-dropdown.component.html',
  styleUrls: ['./common-autocomplete-option-dropdown.component.scss']
})
export class CommonAutocompleteOptionDropdownComponent implements OnInit {

  protected overlayRef: OverlayRef;

  public showing = false;

  @Input()
  public reference: HTMLElement;

  @ViewChild(CdkPortal, {static: true})
  public contentTemplate: CdkPortal;

  constructor(protected overlay: Overlay) {
  }

  ngOnInit(): void {
  }


  public show() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.syncWidth();
    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.showing = true;
  }

  public hide() {
    this.overlayRef.detach();
    this.showing = false;
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({width: refRect.width});
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }, {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      }]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }

}
