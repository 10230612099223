import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UtilsHelper {

  public static IsNilOrEmpty(value: any): boolean {
    if (value === null || value === undefined || value === '') {
      return true
    }

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return false;
  }

}
