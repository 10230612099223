import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { ValetGuard } from './shared/guards/valet.guard';
import { CustomerGuard } from './shared/guards/customer.guard';
import {RedirectGuard} from './shared/guards/redirect.guard';


const routes: Routes = window.location.hostname.startsWith('recrutement') ? [
  {
    path: '',
    loadChildren: () => import('./pv-modules/pv-recruitment/pv-recruitment.module')
      .then(m => m.PvRecruitmentModule),
  }
] : [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'client',
    canLoad: [AuthGuard, ValetGuard],
    loadChildren: () => import('./valet/valet.module')
      .then(m => m.ValetModule),
  },
  {
    path: 'customer',
    canLoad: [AuthGuard, CustomerGuard],
    loadChildren: () => import('./client/client.module')
      .then(m => m.ClientModule),
  },
  {
    path: 'admin',
    canLoad: [AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
  },
  {
    path: 'reservation',
    loadChildren: () => import('./pv-modules/pv-reservation/pv-reservation.module')
      .then(m => m.PvReservationModule),
  },
  {
    path: 'subcontractor',
    loadChildren: () => import('./pv-modules/pv-subcontractor/pv-subcontractor.module')
      .then(m => m.PvSubcontractorModule),
  },
  {
    path: 'mission-data',
    loadChildren: () => import('./common-modules/common-mission-data/common-mission-data.module')
      .then(m => m.CommonMissionDataModule),
  },
  {
    path: '',
    pathMatch: 'full',
    children: [],
    canActivate: [RedirectGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
