import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbButtonModule, NbCardModule,
  NbCheckboxModule,
  NbInputModule, NbLayoutModule,
  NbRadioModule,
  NbSpinnerModule,
  NbToggleModule
} from '@nebular/theme';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedInputValidatorComponent } from './components/shared-input-validator/shared-input-validator.component';
import { SharedInputValidatorItemComponent } from './components/shared-input-validator/shared-input-validator-item/shared-input-validator-item.component';
import { SharedNotFoundPageComponent } from './components/shared-not-found-page/shared-not-found-page.component';
import { CommonTranslateModule } from '../common-modules/common-translate/common-translate.module';
import { SharedInputPhoneNumberComponent } from './components/shared-input-phone-number/shared-input-phone-number.component';
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import { GenericValidationModalComponent } from './components/generic-validation-modal/generic-validation-modal.component';
import {VibrateDirective} from "./directives/vibrate.directive";


@NgModule({
  declarations: [
    SharedInputValidatorComponent,
    SharedInputValidatorItemComponent,
    SharedNotFoundPageComponent,
    SharedInputPhoneNumberComponent,
    GenericValidationModalComponent,
    VibrateDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbToggleModule,
    NbButtonModule,
    NbRadioModule,
    CommonTranslateModule,
    InternationalPhoneNumber2Module,
    NbLayoutModule,
    NbCardModule,
  ],
  exports: [
    NbInputModule,
    NbCheckboxModule,
    ReactiveFormsModule,
    SharedInputValidatorComponent,
    SharedInputValidatorItemComponent,
    NbSpinnerModule,
    NbToggleModule,
    SharedNotFoundPageComponent,
    NbButtonModule,
    NbRadioModule,
    SharedInputPhoneNumberComponent,
    VibrateDirective
  ]
})
export class SharedModule {
}
