import { Pipe, PipeTransform } from '@angular/core';

import { USER_ROLE } from '../../../shared/models/user';

@Pipe({
  name: 'commonRoleTranslate'
})
export class CommonRoleTranslatePipe implements PipeTransform {

  transform(value: USER_ROLE): any {
    switch (value) {
      case USER_ROLE.ADMIN:
        return 'Admin';
      case USER_ROLE.VALET:
        return 'Valet';
      case USER_ROLE.CUSTOMER_ADMIN:
        return 'Superviseur';
      case USER_ROLE.CUSTOMER_READ_ONLY:
        return 'Utilisateur (visualisation)';
      case USER_ROLE.CUSTOMER_USER:
        return 'Utilisateur';
    }
    return 'Wrong role';
  }

}
