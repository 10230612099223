export interface ValetDocument {
  isSubmitting?: boolean;
  url?: string | File;
  key?: string;
  documentType: VALET_DOCUMENT_TYPE;
  status?: VALET_DOCUMENT_STATUS;
  expiryDate?: any;
  comment?: string;
}

export enum VALET_DOCUMENT_STATUS {
  PENDING = 'PENDING',
  VALID = 'VALID',
  INVALID = 'INVALID',
  MISSING = 'MISSING',
}

export interface ValetDocuments {
  idCard: ValetDocument;
  idCardVerso: ValetDocument;
  proofOfAddress: ValetDocument;
  drivingLicense: ValetDocument;
  drivingLicenseVerso: ValetDocument;
  extractKbis: ValetDocument;
  vitalCard: ValetDocument;
  certificateURSSAF: ValetDocument;
  contract: ValetDocument;
  residencePermit: ValetDocument;
}

export enum VALET_DOCUMENT_TYPE {
  idCard = 'idCard',
  idCardVerso = 'idCardVerso',
  proofOfAddress = 'proofOfAddress',
  drivingLicense = 'drivingLicense',
  drivingLicenseVerso = 'drivingLicenseVerso',
  insurance = 'insurance',
  rib = 'rib',
  extractKbis = 'extractKbis',
  vitalCard = 'vitalCard',
  certificateURSSAF = 'certificateURSSAF',
  contract = 'contract',
  residencePermit = 'residencePermit'
}
