import { Directive, ElementRef, Renderer2, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[pvVibrate]'
})
export class VibrateDirective implements OnDestroy {
  private timeoutId: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input('pvVibrate') set vibrate(times: number) {
    if (!times) return;
    this.startVibration(times);
  }

  private startVibration(times: number) {
    this.renderer.addClass(this.el.nativeElement, 'vibrate');

    this.timeoutId = setTimeout(() => {
      this.renderer.removeClass(this.el.nativeElement, 'vibrate');
    }, Math.min(times * 300, 1200));
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
