import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonAutocompleteOptionDropdownComponent } from './components/common-autocomplete-option-dropdown/common-autocomplete-option-dropdown.component';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { CommonAutocompleteOptionListComponent } from './components/common-autocomplete-option-list/common-autocomplete-option-list.component';
import {debounceTime, distinctUntilChanged, filter, skip, switchMap, takeUntil, tap} from 'rxjs/operators';
import Fuse, {FuseResult} from 'fuse.js'
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';

export const COMMON_AUTOCOMPLETE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CommonAutocompleteComponent),
  multi: true,
};

export interface CommonAutocompleteItem {
  name: string;
  value?: any;
  search?: string;
  highlighted?: boolean;
  children?: CommonAutocompleteItem[];
}

export type AutoCompleteAsyncFectcher = (search: string, offset: number, limit: number) => Observable<CommonAutocompleteItem[]>;

const defaultFuseOptions = {
  minMatchCharLength: 2, // Default is 1
  keys: ['name'],
  threshold: 0.3 // Default is 0.6
}
@Component({
  selector: 'pv-common-autocomplete',
  templateUrl: './common-autocomplete.component.html',
  styleUrls: ['./common-autocomplete.component.scss'],
  providers: [
    COMMON_AUTOCOMPLETE_VALUE_ACCESSOR
  ]
})
export class CommonAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public valuesArray: any; // string or []
  public valuesSet: Set<any>;
  public valuesNames = '';
  public allItems: Map<string, CommonAutocompleteItem>;
  public displayedItems: CommonAutocompleteItem[];
  public searchText: string;
  public isLoadingOptions = true;
  public disabled: boolean;
  public fuse: Fuse<CommonAutocompleteItem> = new Fuse([], { ...defaultFuseOptions });

  private destroy$: Subject<void> = new Subject<void>();
  private search$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private offset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private noMoreResults = false;

  @Input() asyncFetcher: AutoCompleteAsyncFectcher;
  @Input() limit = 25;
  @Input() multiple = false;
  @Input() autocomplete: boolean;
  @Input() minWidth: boolean;
  @Input() small: boolean;
  @Input() isMissionDetails: boolean;
  @Input() placeholder = '';
  @Input() newButton = false;
  @Input() deselectEntitiesButton = false;
  @Input() newButtonText = '';
  @Input() fuseOption = {};
  @Input() localSearch = true;
  @Input('selectedItem')
  set setSelectedItem(selectedItem: string) {
    if (selectedItem) {
      this.valuesSet.add(selectedItem);
      this.updateSingleSelectValue();
    }
  }

  @Input('selectedItems')
  set setSelectedItems(selectedItems: string[]) {
    if (selectedItems.length) {
      selectedItems.forEach(item => this.valuesSet.add(item));
      this.valuesSet.add(selectedItems);
      this.updateMultiSelectValues();
    }
  }

  @Input('selectItems')
  set selectItemsData(selectItems: CommonAutocompleteItem[]) {
    if (selectItems) {
      this.allItems = new Map(selectItems.map(v => [v.value, v]));
      this.fuse.setCollection(selectItems);
      this.displayNextItems(true);
      this.updateMultiSelectValues();
      this.isLoadingOptions = false;
    } else {
      this.isLoadingOptions = true;
    }
  }

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() newButtonClicked = new EventEmitter();
  @Output() bottomReached = new EventEmitter();
  @Output() searchTextChanged = new EventEmitter();
  /*

  // , {descendants: true}
    @ContentChildren(CommonAutocompleteOptionComponent) options: QueryList<CommonAutocompleteOptionComponent>;
  */

  @ViewChild('input', {static: true})
  public input: ElementRef;

  @ViewChild(CommonAutocompleteOptionDropdownComponent, {static: true})
  public dropdown: CommonAutocompleteOptionDropdownComponent;

  @ViewChild(CommonAutocompleteOptionListComponent, {static: true})
  public optionList: CommonAutocompleteOptionListComponent;

  constructor() {
    this.valuesSet = new Set();
  }

  ngOnInit(): void {
    if (this.asyncFetcher) {
      this.initAsyncFetcher()
    } else {
      this.initLocalSearch()
    }
  }

  private initAsyncFetcher() {
    const searched$ = this.search$.pipe(
      distinctUntilChanged(),
      tap(_ => this.offset$.next(0)),
      tap(_ => this.displayedItems = []),
      tap(_ => this.noMoreResults = false)
    )

    combineLatest([searched$, this.offset$])
      .pipe(
        debounceTime(200),
        filter(_ => !this.noMoreResults),
        switchMap(([search, offset]) => this.asyncFetcher(search, offset, this.limit)),
        takeUntil(this.destroy$)
      ).subscribe((commonAutoCompleteItems) => {
      this.isLoadingOptions = false;
      this.searchText = this.search$.value;
        this.noMoreResults = commonAutoCompleteItems.length < this.limit;
        this.displayedItems = this.displayedItems.concat(commonAutoCompleteItems);
        if (!this.allItems) this.allItems = new Map(this.displayedItems.map(v => [v.value, v]));
        else {
          commonAutoCompleteItems.forEach((v) => {
            if (!this.allItems.has(v.value)) this.allItems.set(v.value, v);
          })
        }
        this.searchTextChanged.emit(this.searchText);
    })
  }

  private initLocalSearch() {
    this.search$.pipe(
      skip(1),
      takeUntil(this.destroy$),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(searchText => {
      this.searchText = searchText;
      this.displayNextItems(true);
      this.searchTextChanged.emit(this.searchText);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  public showDropdown() {
    this.dropdown.show();
    this.optionList.focusSearchInput();
  }

  public onBlur() {
    this.onTouched();
  }

  public selectItem(event) {
    if (!this.multiple) {
      this.valuesSet.clear();
      this.dropdown.hide();
    }

    if (event.selected) {
      this.valuesSet.add(event.item.value);
    } else {
      this.valuesSet.delete(event.item.value);
    }
    if (this.multiple) {
      this.updateMultiSelectValues();
    } else {
      this.updateSingleSelectValue();
    }
    this.emitChanges();
    this.selectedChange.emit(this.valuesArray);
  }

  public selectMultipleItems(event) {
    if (event.selected) {
      event.items.forEach(item => this.valuesSet.add(event.item.value));
    } else {
      event.items.forEach(item => this.valuesSet.delete(event.item.value));
    }
    this.updateMultiSelectValues();
    this.emitChanges();
  }

  // CUSTOM FORM CONTROL FUNCTIONS
  emitChanges() {
    this.onChange(this.valuesArray);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (this.multiple) {
      this.updateMultiSelectValues(obj);
    } else {
      this.updateSingleSelectValue(obj);
    }
  }

  updateSingleSelectValue(selectedValue?: string) {
    if (selectedValue) {
      this.valuesSet = new Set([selectedValue]);
    }
    this.valuesArray = Array.from(this.valuesSet).join();
    this.updateSingleSelectName();
  }

  updateMultiSelectValues(selectedValues?: string[]) {
    if (selectedValues) {
      this.valuesSet = new Set(selectedValues);
    }
    this.valuesArray = Array.from(this.valuesSet);
    this.updateMultiSelectNames();
  }

  updateMultiSelectNames() {
    const names = [];
    if (this.allItems) {
      Array.from(this.allItems.values()).forEach(item => {
        if (item.children) {
          item.children.forEach(childItem => {
            if (this.valuesSet.has(childItem.value)) {
              names.push(childItem.name);
            }
          });
        } else {
          if (this.valuesSet.has(item.value)) {
            names.push(item.name);
          }
        }
      });
    }
    this.valuesNames = names.join(', ');
  }

  updateSingleSelectName() {
    let name = '';
    if (this.allItems) {
      Array.from(this.allItems.values()).some(item => {
        if (item.children) {
          item.children.some(childItem => {
            if (this.valuesSet.has(childItem.value)) {
              name = childItem.name;
              return true;
            }
          });
        } else {
          if (this.valuesSet.has(item.value)) {
            name = item.name;
          }
        }
      });
    }
    this.valuesNames = name;
  }

  onNewButtonClicked() {
    this.dropdown.hide();
    this.newButtonClicked.emit();
  }

  onBottomReached() {
    if (this.asyncFetcher) {
      this.offset$.next(this.offset$.value + this.limit)
    } else {
      this.displayNextItems(false);
    }
    this.bottomReached.emit();
  }

  private displayNextItems(resetDisplayedItems: boolean) {
    if (resetDisplayedItems) {
      this.displayedItems = [];
    }

    let filteredItems: CommonAutocompleteItem[];
    if (this.localSearch && this.searchText?.length >= defaultFuseOptions.minMatchCharLength) {
      filteredItems = this.fuse.search(this.searchText).map(v => v.item)
    } else {
      filteredItems = Array.from(this.allItems.values());
    }

    // Add 100 new items max
    const itemsToAdd = Math.min(filteredItems.length - this.displayedItems.length, 100);
    if (itemsToAdd > 0) {
      this.displayedItems = filteredItems.slice(0, this.displayedItems.length + itemsToAdd);
    }
  }

  onSearchChanged(searchText: string) {
    this.search$.next(searchText);
  }

  clearSelectedItems() {
    this.valuesSet.clear();
    this.updateSingleSelectValue();
    this.emitChanges();
  }
}
